import React, { useState } from "react";
import "./pages.css";

const Attendance = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submissionError, setError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const actualDay = new Date();

    if (
      actualDay.getDay() !== 0 || //0
      actualDay.getHours() < 11 ||
      actualDay.getHours() > 16 //16
    ) {
      setError(true);
      setTimeout(() => setError(false), 3000);
    } else {
      try {
        setSubmitted(true);
        const formData = `fullName=${encodeURIComponent(
          fullName
        )}&email=${encodeURIComponent(email)}`;

        await fetch(
          "https://script.google.com/macros/s/AKfycbyC_--MoI9q5jS7hncrojbIN4bgqP3FclXqdS5Yz8_L95bPYnE8l62IVdL0xFvP1kJ0/exec",
          {
            method: "POST",
            mode: "no-cors",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData,
          }
        );

        console.log("Form data sent");
        setFullName("");
        setEmail("");
        //setSubmitted(true);
        //setTimeout(() => setSubmitted(false), 3000);
      } catch (error) {
        console.error("There was an error submitting the data:", error);
        alert("Error submitting data. Please try again.");
      }
    }
  };

  const today = new Date();
  while (today.getDay() !== 0) {
    today.setDate(today.getDate() - 1);
  }
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const day = today.getDate();

  return (
    <div className="pageBodyBed">
      <div className="pageBodyContainer">
        <h1 className="pageHeader">
          Attendance Form: {month}/{day}/{year}
        </h1>
        <p className="pageBody">
          We are lucky to be sponsored by a bridge enthusiast, who rewards
          regular members with $15 per meeting for attendance! Fill out the form
          below at the beginning of each meeting.
        </p>

        {submitted && (
          <div className="notification">Form submitted successfully!</div>
        )}
        {submissionError && (
          <div className="errorNotification">
            Error: Currently not accepting submissions
          </div>
        )}

        <form
          onSubmit={handleSubmit}
          className={`attendenceForm ${submitted ? "blur" : ""}`}
        >
          <div className="formGroup">
            <label className="formLabel">
              Full Name:
              <input
                type="text"
                className="formInput"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
                disabled={submitted} // Disable input after submission
              />
            </label>
          </div>
          <div className="formGroup">
            <label className="formLabel">
              Email Address:
              <input
                type="email"
                className="formInput"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={submitted} // Disable input after submission
              />
            </label>
          </div>
          <input type="submit" className="formSubmit" disabled={submitted} />
        </form>
      </div>
    </div>
  );
};

export default Attendance;
